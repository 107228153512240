import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FirstPersonControls } from "three/addons/controls/FirstPersonControls.js";

// Canvas
const canvas = document.querySelector("canvas.webgl");
const canvasFrame = document.querySelector(".webglFrame");
var convasHeight = canvas.getBoundingClientRect().height;
var convasWidth = canvas.getBoundingClientRect().width;
var convasFrameHeight = canvas.getBoundingClientRect().height;

// Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color("#fff");
/**
 * Object
 */
const geometry = new THREE.BoxGeometry(1, 1, 1);

var torus = new THREE.Scene();
torus.rotation.z = Math.PI;
scene.add(torus);

const p_geometry = new THREE.SphereGeometry(0.1, 16, 16);

var materials = [];

var p = 0;

const a = 3;
const b = 1;
const k = 0.001;

const scaleFactor = 10; // Adjust this for better visualization

for (let u = 0; u <= 1000 * 2 * Math.PI; u += 1) {
  var p_material = new THREE.MeshBasicMaterial();

  const col = Math.round(Math.random() * 3);

  p_material.color.set(new THREE.Color("#000"));
  //p_material.color.opacity.set(0.5);

  const v = k * u;

  const x = (a + b * Math.cos(u)) * Math.cos(v) * scaleFactor;
  const y = (a + b * Math.cos(u)) * Math.sin(v) * scaleFactor;
  const z = b * Math.sin(u) * scaleFactor;

  var particle = new THREE.Mesh(p_geometry, p_material);

  particle.dx = Math.random() * 10 - 5;
  particle.dy = Math.random() * 10 - 5;
  particle.dz = Math.random() * 10 - 5;
  particle.theta = Math.random() * 2 * Math.PI * 0.04;
  particle.s = 2;

  particle.position.x = x + particle.s * particle.dx * Math.sin(particle.theta);
  particle.position.y = y + particle.s * particle.dy * Math.sin(particle.theta);
  particle.position.z = z + particle.s * particle.dz * Math.sin(particle.theta);

  particle.ox = x;
  particle.oy = y;
  particle.oz = z;

  torus.add(particle);
  p++;
}

function anim(scenex) {
  for (p = 0; p < scenex.children.length; p++) {
    var particle = scenex.children[p];
    particle.position.x =
      particle.ox + particle.s * particle.dx * Math.sin(particle.theta);
    particle.position.y =
      particle.oy + particle.s * particle.dy * Math.sin(particle.theta);
    particle.position.z =
      particle.oz + particle.s * particle.dz * Math.sin(particle.theta);
    particle.theta += 0.004;
  }
}

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(14, convasWidth / convasHeight);
camera.position.z = 120; // 108.29282453443182;
camera.position.x = 120; // 176.04330146998313; //+ 15;

camera.position.y = -45; //88.84861055868186;
/*
camera.rotation.x = 0.8;
camera.rotation.y = 0.8760580505981941;
camera.rotation.z = 0;
*/
scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(convasWidth, convasHeight);
renderer.render(scene, camera);

// Controller
var t = 0;
const controls = new OrbitControls(camera, renderer.domElement);
controls.enableZoom = false;

function animate() {
  t++;
  torus.rotation.z -= 0.0006;
  anim(torus);
  requestAnimationFrame(animate);
  controls.update();
  renderer.render(scene, camera);
}

// Event listener for window resize
window.addEventListener("resize", onWindowResize);
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

animate();
